import { createTheme } from "@mui/material/styles";

const DefaultTheme = createTheme({
    palette: {
        primary: {
            main: '#d8014a'
        },
        secondary: {
            main: '#b6c7f9'
        },
        background: {
            default: '#f4f6f8'
        }
    },
    typography: {
        fontFamily: '"Helvetica", "Arial", sans-serif'
    }
});

export default DefaultTheme;