import { useState } from "react";
import { Container, Typography } from "@mui/material";
import SearchPane from "../components/SearchPane";
import DanglishPane from "../components/DanglishPane";
import ResultsPane from "../components/ResultsPane";
import * as SearchService from '../services/SearchService';


export default function MainView() {

    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState("");

    const handleSearch = async (e) => {
        e.preventDefault();
        const data = await SearchService.search(query);
        setResults(data || []);
        setSelectedChannel("");
    };

    const danglishSearch = async (query) => {
        const data = await SearchService.search(query);
        setResults(data || []);
        setSelectedChannel("");
    };

    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            <Typography variant="h4">DanG Music Encyclopedia</Typography>
            <SearchPane
                query={query}
                setQuery={setQuery}
                handleSearch={handleSearch}
                results={results}
                selectedChannel={selectedChannel}
                setSelectedChannel={setSelectedChannel}
            />
            <DanglishPane
                danglishSearch={danglishSearch}
            />
            <ResultsPane
                results={results}
                selectedChannel={selectedChannel}
            />
        </Container>
    )
}