import MainView from "./views/MainView";


function App() {

    return (
        <MainView />
    )
}

export default App;