import { useMemo } from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@mui/material';


export default function ResultsPane({results, selectedChannel}) {

    const filteredResults = useMemo(() => {
        return selectedChannel
            ? results.filter((item) => item.channel_name === selectedChannel)
            : results;
    }, [results, selectedChannel]);

    return (
        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
            <Table>
                <TableBody>
                    {filteredResults.map((item, index) => (
                        <TableRow
                            key={index}
                            hover
                            style={{ cursor: "pointer" }}
                            onClick={() => window.open(`https://youtu.be/${item.sid}`)}
                        >
                            <TableCell>
                                <img src={item.thumbnail_url} alt={item.title} width="100" />
                            </TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.channel_name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}