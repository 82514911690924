
const API_URL = process.env.REACT_APP_API_URL;

export const search = async (query) => {
    if (!query) {
        console.log("No query specified!");
        return null;
    }
    try {
        const response = await fetch(`${API_URL}/search?terms=${query}`);
        const data = await response.json();
        console.log('Search result: ', data);
        return(data);
    } catch (err) {
        console.log('Error: Unable to search - ', err);
        return null;
    }
};