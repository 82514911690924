import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Button
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function DanglishPane({danglishSearch}) {

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <Typography>DANGLISH me</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" gap={1}>
                    <Button
                        variant='contained'
                        onClick={() => danglishSearch("hydrocity zone")}
                        sx={{ textTransform: "none" }}
                    >GOGOTIAG me</Button>
                    <Button
                        variant='contained'
                        onClick={() => danglishSearch("castlevania simon's theme")}
                        sx={{ textTransform: "none" }}
                    >LUDACAR me</Button>
                    <Button
                        variant='contained'
                        onClick={() => danglishSearch("airman stage megaman 2 remix")}
                        sx={{ textTransform: "none" }}
                    >TAYLOR SWIFT me</Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}