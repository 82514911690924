import { useMemo } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    TextField
} from '@mui/material';


export default function SearchPane({query, setQuery, handleSearch, results, selectedChannel, setSelectedChannel}) {

    const uniqueChannels = useMemo(() => {
        const channels = results.map((item) => item.channel_name);
        return [...new Set(channels)];
    }, [results]);

    return (
        <form onSubmit={handleSearch}>
            <Box display="flex" gap={2} alignItems="center" marginBottom={3}>
                <TextField
                    label="Search by Title"
                    variant="outlined"
                    sx={{ flexGrow: 1 }}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />

                <FormControl sx={{ flexGrow: 1 }}>
                    <InputLabel>Filter By Channel</InputLabel>
                    <Select
                        value={selectedChannel}
                        onChange={(e) => setSelectedChannel(e.target.value)}
                        label="Filter by Channel"
                    >
                        <MenuItem value="">All</MenuItem>
                        {uniqueChannels.map((channel, index) => (
                            <MenuItem key={index} value={channel}>{channel}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button type="submit" variant="contained" sx={{ whiteSpace: "nowrap" }}>
                    Search
                </Button>
            </Box>
        </form>
    )
}

